export default {
    defaultConfigContext: {
        email: "lasergamecl@email.cz",
        tel: "777 106 429",
        reservation_done_text: "",
        reservation_cancel_text: ""
    },
    mapbox: {
        token: 'pk.eyJ1IjoiamV0YW0iLCJhIjoiY2tqMGpyZmhiMmx4MDJ2bW1rNXl0NjZ5OSJ9._kpgJq5iwyyDKkYBDUE_9g'
    },
    recaptchaApiKey: "6Lf9MCAaAAAAALx6Da4nIqkPoywi7S471XfE5Zqo"
}

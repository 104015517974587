import React, {useEffect} from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Reservation from "./pages/Reservation";
import Error from "./pages/error";
import SimplePage from "./pages/SimplePage";
import Contact from "./pages/Contact";
//import { loadReCaptcha } from 'react-recaptcha-v3'
import config from "./consts/config";
import DoneReservation from "./pages/DoneReservation";
import CancelReservation from "./pages/CancelReservation";
import NewsView from "./pages/NewsView";
import ReservationBlock from "./pages/Rezervace-blok";

const App = () => {
    useEffect(() => {
        //loadReCaptcha(config.recaptchaApiKey, () => {});
    }, [])

    return (
        <Router>
            <Routes>
                <Route exact path="/uvod" element={<Navigate to="/"/>} />

                <Route exact path="/" element={(
                    <Layout>
                        <Home/>
                    </Layout>
                )} />


                <Route path="/cenik" element={(
                    <Layout>
                        <SimplePage id={8}/>
                    </Layout>
                )} />

                <Route path="/rezervace/:code/:hash" element={(
                    <Layout>
                        <CancelReservation/>
                    </Layout>
                )} />

                <Route path="/rezervace/:code" element={(
                    <Layout>
                        <DoneReservation/>
                    </Layout>
                )} />

                <Route path="/rezervace" element={(
                    <Layout>
                        <Reservation/>
                    </Layout>
                )} />

                <Route path="/o-laser-game" element={(
                    <Layout>
                        <SimplePage id={1}/>
                    </Layout>
                )} />

                <Route path="/herni-mody" element={(
                    <Layout>
                        <SimplePage id={2}/>
                    </Layout>
                )} />

                <Route path="/faq" element={(
                    <Layout>
                        <SimplePage id={3}/>
                    </Layout>
                )} />

                <Route path="/provozni-rad" element={(
                    <Layout>
                        <SimplePage id={6}/>
                    </Layout>
                )} />

                <Route path="/poukazy" element={(
                    <Layout>
                        <SimplePage slug="vouchers"/>
                    </Layout>
                )} />

                <Route path="/kontakt" element={(
                    <Layout>
                        <Contact/>
                    </Layout>
                )}/>

                <Route path="/page/:slug" element={(
                    <Layout>
                        <SimplePage/>
                    </Layout>
                )} />

                <Route path="/aktualita/:id" element={(
                    <Layout>
                        <NewsView/>
                    </Layout>
                )} />
                <Route path="*" element={(
                    <Layout>
                        <Error/>
                    </Layout>
                )} />
            </Routes>
        </Router>
    );
}

export default App;

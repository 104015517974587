export default {
    months: [
        'Leden',
        'Únor',
        'Březen',
        'Duben',
        'Květen',
        'Červen',
        'Červenec',
        'Srpen',
        'Září',
        'Říjen',
        'Listopad',
        'Prosinec'
    ],
    months2: [
        'Ledna',
        'Února',
        'Březena',
        'Dubna',
        'Května',
        'Června',
        'Července',
        'Srpna',
        'Září',
        'Října',
        'Listopadu',
        'Prosince'
    ]
}
import React from "react";
import config from "../consts/config";
import AxiosInstance from "../utils/AxiosInstance";

export const IndexContext = React.createContext();

class IndexContextProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {data: undefined}
    }

    componentWillMount() {
        AxiosInstance.get('/index')
            .then((res) => {
                this.setState({data: res.data})
            })
            .catch(() => {

            })
    }

    render() {
        return (
            <IndexContext.Provider value={{...this.state.data}}>{this.props.children}</IndexContext.Provider>
        )
    }
}

export default IndexContextProvider
import React from 'react';

import styles from './slides.module.css';
import WelcomeSlide from "./WelcomeSlide";

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import {Autoplay, EffectFade, Navigation, Pagination, Scrollbar} from "swiper/modules";

const Slides = (props) => {
    const hasImages = props.slides.find(item => item.image)

    return (
        <div className={hasImages ? `${styles.wrapper} ${styles.hasImages}` : styles.wrapper }>
            <Swiper
                className={hasImages ? `${styles.slider} ${styles.hasImages}` : styles.slider}
                spaceBetween={0}
                slidesPerView={1}
                modules={[Navigation, Pagination, Autoplay, EffectFade]}
                loop={props.slides.length}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: props.slides.length,
                }}
                navigation={true}
                effect={'fade'}
            >
                {
                    props.slides?.map((slide, index) => (
                        <SwiperSlide className={hasImages ? `${styles.slide} ${styles.hasImages}` : styles.slide }>
                            <WelcomeSlide welcomeSlide={slide} hasImages={hasImages} key={index}/>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}

export default Slides
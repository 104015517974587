import React, {useState} from "react";
import Map from "../components/index/Map";
import SimplePage from "./SimplePage";
import ContactForm from "../components/contactForm";

const Contact = () => {
    return (
        <div className="page-contact">
            <SimplePage id={4}/>
            <ContactForm/>
            <Map/>
        </div>
    )
}

export default Contact
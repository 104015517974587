import React, {useEffect, useState} from "react";

const Features = (props) => {
    const {features} = props

    const [modal, setModal] = useState(null)

    return (
        <>
            <div className="features-grid">
                <div className="features">
                    <div className="grid">
                        {
                            features.map((feature) =>
                                <div className={feature.modal ? "item hasLink" : "item"} onClick={() => {
                                    window.gtag('event', 'feature_view', {
                                        featureID: feature.id,
                                        send_to: 'G-4P48TWG8PB'
                                    });
                                    setModal(feature.modal)
                                }}>
                                    <img src={process.env.REACT_APP_API_STORAGE + "img/icons/" + feature.icon.replaceAll('.', '/') +".svg"} alt={feature.icon}/>
                                    <div className="text">
                                        <h3>{feature.title}</h3>
                                        <p>{feature.text}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            {
                modal && (
                    <div className="featuresModal" onClick={(e) => {
                        if(e.target.classList.contains("featuresModal")) {
                            setModal(null)
                        }
                    }}>
                        <div className="modalBox">
                            <div className="close" onClick={() => setModal(null)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </div>
                            <div className="content" dangerouslySetInnerHTML={{__html: modal}}>

                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Features
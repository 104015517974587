import React, {useEffect, useState} from "react";
import PageHeader from "../components/PageHeader";
import {useParams} from "react-router-dom";
import Error from "./error";
import AxiosInstance from "../utils/AxiosInstance";
import Loader from "../components/Loader";
import useSWR from 'swr'

const SimplePage = (props) => {
    const states = {
        LOADING: 0,
        DONE: 1,
        ERROR: 2
    }

    const fetcher = (...args) => fetch(...args).then(res => res.json())

    const {slug} = useParams()

    const url = props.id ? '/pages/'+props.id : '/pages-slug/'+(props.slug || slug)
    const { data, error } = useSWR(process.env.REACT_APP_API+url, fetcher)

    const showHeader = () => {
        return props.header || (typeof props.header === 'undefined')
    }

    return (
        <div className="page-simplePage">
            {
                !error ? (
                    data ? (
                        <>
                            {showHeader && <PageHeader title={data.title}/>}
                            <div className="container article" dangerouslySetInnerHTML={{__html: data.text}}></div>
                        </>
                    ) : (
                        <Loader/>
                    )
                ) : (
                    <Error errorCode={404}/>
                )
            }
        </div>
    )
}

export default SimplePage
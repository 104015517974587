import React, {useMemo} from "react";

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import {Autoplay, EffectFade, Navigation, Pagination, Scrollbar} from "swiper/modules";
import useWindowSize from "../utils/windowSize";


const Gallery = (props) => {
    const { width } = useWindowSize();

    const slidesPerView = useMemo(() => {
        if (width < 600) return 1;
        if (width < 1000) return 2;
        if (width < 1800) return 3;
        return 4;
    }, [width]);

    return (
        <Swiper
            spaceBetween={20}
            slidesPerView={slidesPerView}
            modules={[Navigation, Pagination, Autoplay, EffectFade]}
            loop
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            navigation={true}
            style={{width: "calc(100% - 40px); margin-right: auto;"}}
            centeredSlides
        >
            {
                props.images.map((image, index) =>
                    <SwiperSlide>
                        <div className="gallery-image"
                             style={{backgroundImage: "url(" + process.env.REACT_APP_API_STORAGE + "images/gallery/" + image["image"] + ")"}}
                        >
                        </div>
                    </SwiperSlide>
                )
            }
        </Swiper>
    )
}

export default Gallery
import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import AxiosInstance from "../utils/AxiosInstance";
import {ConfigContext} from "../providers/ConfigProvider";

const CancelReservation = () => {
    const states = {
        "CHECK": 0,
        "DONE": 1
    }

    const {code, hash} = useParams()
    const [data, setData] = useState(null)
    const [status, setStatus] = useState(states.CHECK)

    const config = useContext(ConfigContext)

    useEffect(() => {
        AxiosInstance.get('/reservations/code/'+code)
            .then(() => {})
            .catch(() =>{
                setData({message: "Rezervace neexistuje"})
                setStatus(states.DONE)
            })
    }, [code, hash])

    const handleCancelBtn = () => {
        setStatus(states.DONE)

        AxiosInstance.delete('/reservations/code/' + code + '/' + hash)
            .then(res => {
                setData(res.data)
            })
            .catch(err => {
                if (err?.response?.data) {
                    setData(err.response.data)
                }
            })
    }

    return (
        <div className="page-doneReservation">
            <div className="ticket">
                <div className="header">
                    <h1>Zrušení rezervace</h1>
                    <div className="number">kód rezervace: <div className="code">{code}</div></div>
                </div>

                {
                    status === states.DONE ? (
                        <div className="ticket-content text-center">
                            <h3>{data ? data.message : "Načítání..."}</h3>
                        </div>

                    ) : (
                        <>
                            <div className="ticket-content"
                                 dangerouslySetInnerHTML={{__html: config.reservation_cancel_text}}></div>

                            <div className="ticket-content flexCenter">
                                <div className="btn btn-danger" onClick={handleCancelBtn}>Ano, chci ji zrušit</div>
                                <Link to="/">
                                    <div className="btn btn-line">Ne, chci ji ponechat</div>
                                </Link>
                            </div>
                        </>
                    )
                }

                <div className="sign">
                    Těšíme se na Vaši další návštěvu.
                </div>
            </div>
        </div>
    )
}

export default CancelReservation
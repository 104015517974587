import React from "react";
import AxiosInstance from "../utils/AxiosInstance";

export const MenuContext = React.createContext({error: false});

class MenuContextProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {data: undefined, error: false}
    }

    componentWillMount() {
        if(window.localStorage.getItem("menu-data")) {
            this.setState({data: JSON.parse(window.localStorage.getItem("menu-data"))})
        }

        this.update()
    }

    update() {
        AxiosInstance.get('/menus')
            .then((res) => {
                this.setState({data: res.data})

                window.localStorage.setItem("menu-data", JSON.stringify(res.data))
            })
            .catch(() => {
                this.setState({error: true})
            })
    }

    tryAgain() {
        this.setState({error: false})
        this.update()
    }

    render() {
        return (
            <MenuContext.Provider value={{...this.state.data, tryAgain: this.tryAgain.bind(this)}}>{this.props.children}</MenuContext.Provider>
        )
    }
}

export default MenuContextProvider
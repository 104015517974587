import React, {useState, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {ConfigContext} from "../providers/ConfigProvider";
import {MenuContext} from "../providers/MenuProvider";
import Loader from "./Loader";

const Menu = (props) => {
    const [scrolled, setScrolled] = useState(false);
    const [open, setOpen] = useState(false)

    const menu = useContext(MenuContext)

    const configContext = useContext(ConfigContext);

    useEffect(() => {
        const onScroll = () =>{
            setScrolled(window.pageYOffset > 50)
        }

        onScroll()

        document.addEventListener('scroll', onScroll)

        return function cleanup() {
            document.removeEventListener('scroll', onScroll)
        };
    }, [])

    if(!menu.main) {
        return <Loader/>
    }

    return (
        <div className={(scrolled || props.scroller || open) ? "menu-frame scrolled" : "menu-frame"}>
            <div className="contact-line">
                <div className="container">
                    <div className="item"><a href={"tel:"+configContext.tel}>(+420) {configContext.tel}</a></div>
                    {window.innerWidth > 395 && <div className="item"><a href={"mailto:"+configContext.email}>{configContext.email}</a></div>}
                </div>
            </div>
            <div className="container menu">
                <div className="logo">
                    <Link to="/"><img src={`/img/${process.env.REACT_APP_LOGO}`} alt="Logo"/></Link>
                </div>
                <div className="burger" onClick={() => setOpen(!open)}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
                <div className="nav">
                    {
                        menu.main.map(item =>
                            <Link key={item.href} to={item.href}><div className="item">{item.title}</div></Link>
                        )
                    }
                    <Link to="/rezervace">
                        <div className="item">
                            <div className="btn btn-success">Online rezervace</div>
                        </div>
                    </Link>
                </div>
            </div>
            {open && (
                <div className="fix-nav" onClick={() => setOpen(false)}>
                    {
                        menu.main.map(item =>
                            <Link key={item.href} to={item.href}><div className="item">{item.title}</div></Link>
                        )
                    }
                    <Link to="/rezervace">
                        <div className="item">
                            <div className="btn btn-success">Online rezervace</div>
                        </div>
                    </Link>
                </div>
            )}
        </div>
    )
}

export default Menu

import React from "react";
import {Link} from "react-router-dom";

const WelcomeSlide = (props) => {
    return (
        <div className={props.hasImages ? "welcome hasImages" : "welcome"}>
            <div className="bg-image"></div>
            <div className="circle"></div>

            {
                props.welcomeSlide.image ? (
                    <img className="image"
                         src={`${process.env.REACT_APP_API_STORAGE}images/welcome/${props.welcomeSlide?.image}`} alt=""/>

                ) : (
                    <div className="people"></div>
                )
            }


            <div className="text">
                <h1 dangerouslySetInnerHTML={{__html: props.welcomeSlide.title.replace(/\n/g, "<br/>")}}></h1>
                <p className="line">{props.welcomeSlide.text}</p>

                <Link to="/rezervace">
                    <div className="btn btn-success">
                        Online rezervace
                    </div>
                </Link>
                <Link to="/o-laser-game">
                    <div className="btn btn-line">
                        Co je to Laser Game?
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default WelcomeSlide
import React, {useEffect, useState} from "react";
import PageHeader from "../components/PageHeader";
import {useParams} from "react-router-dom";
import Error from "./error";
import AxiosInstance from "../utils/AxiosInstance";
import months from "../consts/months";

const NewsView = (props) => {
    const states = {
        LOADING: 0,
        DONE: 1,
        ERROR: 2
    }

    const [data, setData] = useState(null)
    const [state, setState] = useState(states.LOADING)

    const {id} = useParams()

    useEffect(() => {
        AxiosInstance.get('/news/'+id)
            .then(res => {
                setData(res.data)
                setState(states.DONE)
            })
            .catch(() => {
                setState(states.ERROR)
            })

    }, [props, id])

    const showHeader = () => {
        return props.header || (typeof props.header === 'undefined')
    }

    const getDay = (date) => {
        const d = new Date(date)

        return d.getDate() + '. ' + months.months2[d.getMonth()] + ' ' + d.getFullYear()
    }


    return (
        <div className="page-simplePage">
            {
                state !== states.ERROR ? (
                    data ? (
                        <>
                            {showHeader && <PageHeader title={'Aktualita'}/>}
                            <div className="container article">
                                <div className="aticle-title">
                                    <div className="article-sub">
                                        {
                                            data.type === "OPENING-HOURS" ? (
                                                <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    otevírací doba
                                                </>
                                            ) : (
                                                <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    info
                                                </>
                                            )
                                        }
                                    </div>
                                    <h2>{data.title}</h2>
                                    <div className="date">{getDay(data.date)}</div>
                                </div>
                                <span dangerouslySetInnerHTML={{__html: data.long_text}}></span>
                            </div>
                        </>
                    ) : (
                        <>
                            {showHeader && <PageHeader title="Načítání..."/>}
                            <div className="container article"></div>
                        </>
                    )
                ) : (
                    <Error errorCode={404}/>
                )
            }
        </div>
    )
}

export default NewsView
import React from "react";
import config from "../consts/config";
import AxiosInstance from "../utils/AxiosInstance";

export const ConfigContext = React.createContext();

class ConfigContextProvider extends React.Component {
    constructor(props) {
        super(props)

        this.state = {...config.defaultConfigContext, error: false}
    }

    componentWillMount() {
        if(window.localStorage.getItem("settings-data")) {
            this.setState(JSON.parse(window.localStorage.getItem("settings-data")))
        }

        this.update()
    }

    update() {
        AxiosInstance.get('/settings')
            .then((res) => {
                const newData = {}

                res.data.forEach(item => {
                    newData[item.config_key] = item.value
                })

                //this.setState(newData)

                window.localStorage.setItem("settings-data", JSON.stringify(newData))
            })
            .catch(() => {
                this.setState({error: true})
            })
    }

    tryAgain() {
        this.setState({error: false})
        this.update()
    }

    render() {
        return (
            <ConfigContext.Provider value={{...this.state, tryAgain: this.tryAgain.bind(this)}}>{this.props.children}</ConfigContext.Provider>
        )
    }
}

export default ConfigContextProvider
import WelcomeSlide from '../components/index/Slides/WelcomeSlide'
import Features from "../components/index/Features";
import News from "../components/index/Nexs";
import DragList from "../components/DragList";
import {Link} from "react-router-dom";
import React, {useContext} from "react";
import Map from "../components/index/Map";
import Loader from "../components/Loader";
import {IndexContext} from "../providers/IndexProvider";
import Slides from "../components/index/Slides/Slides";
import Gallery from "../components/Gallery";

const Home = () => {
    const indexdata = useContext(IndexContext)

    if(!indexdata.features) {
        return (<Loader/>)
    }

    return (
        <div className="page-index">
            <section>
                <Slides slides={indexdata.welcomeSlides ? indexdata.welcomeSlides : [indexdata.welcomeSlide]}/>
                <Features features={indexdata.features}/>
            </section>

            <div className="gallery-frame">
                <div className="container">
                    <h1 className="main">Jak to u nás vypadá</h1>
                </div>

                {/*}
                <DragList
                    loop={true}
                    slide
                    slideType="hard"
                >
                    {
                        indexdata.gallery.map((image, index) =>
                            <div className="gallery-image"
                                 style={{backgroundImage: "url("+process.env.REACT_APP_API_STORAGE+"images/gallery/" + image["image"]+")"}}
                            >
                            </div>
                        )
                    }
                </DragList>
                */}

                <Gallery images={indexdata.gallery}/>
            </div>

            <News news={indexdata.news}/>


            <div className="giftCards-frame">
                <div className="bg"></div>
                <div className="text">
                    <div className="container box">
                        <div className="image">
                            <img src={process.env.REACT_APP_API_STORAGE + "images/"+indexdata.vouchers.image} alt="Dárkový poukaz"/>
                        </div>
                        <div className="info">
                            <h4>{indexdata.vouchers.title}</h4>
                            <p className="line">{indexdata.vouchers.text}</p>
                            <div className="btns">

                                {
                                    indexdata.vouchers.href.includes("http") ? (
                                        <a href={indexdata.vouchers.href} target="_blank">
                                            <div className="btn btn-success">
                                                Koupit poukaz
                                            </div>
                                        </a>
                                    ) : (
                                        <Link to={indexdata.vouchers.href}>
                                            <div className="btn btn-success">
                                                Koupit poukaz
                                            </div>
                                        </Link>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Map/>
        </div>
    )
}

export default Home
